<template>
  <!-- 外层壳子 -->
  <div class="infocontent">
    <div class="leftHeaderBar">
      <header>
        <div class="imgBg">
          <img v-if="avatar" class="avatar" :src="avatar ? avatar : defaultAvatar" alt="" />
          <div v-else class="avatar avatardefault">{{ avatarName }}</div>
        </div>
        <p class="name">{{ userName }}</p>
        <p class="position">{{ jobName }}</p>
        <p class="department">{{ orgChain }}</p>
        <div class="progress">
          <el-progress :percentage="perfectNum" :format="format"></el-progress>
        </div>
      </header>
      <div class="attention">
        <div
          :class="topoptiontext === item.val ? 'activeattention_option' : ''"
          class="attention_option"
          @click="routerPush(item)"
          v-for="item in attention"
          :key="item.text"
        >
          <img class="avatar" :src="item.imgurl" alt="" />
          <div class="attention_banner">
            <p>{{ item.val }}</p>
            <p>{{ item.explanation }}</p>
          </div>
          <i class="iconfont iconjiantou"></i>
        </div>
      </div>
      <div class="wedata">
        <div
          :class="topoptiontext === item.val ? 'activeLeftbar' : ''"
          class="wedataList"
          @click="routerPush(item)"
          v-for="item in wedata"
          :key="item.index"
        >
          <div>
            <i class="iconfont" :class="item.class"></i>
            <p>{{ item.val }}</p>
          </div>
          <i class="iconfont iconjiantou"></i>
        </div>
      </div>
    </div>
    <div class="conterHeaderbar">
      <div class="feedBack" v-if="topoptiontext === '意见反馈'">意见反馈</div>
      <!-- <tojoy-title-content  v-if="topoptiontext === '意见反馈'" :title="topoptiontext" /> -->
      <div :class="topoptiontext === '意见反馈' ? 'contentRouterview' : 'notcontentRouterview'">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
// import { count } from '@/api/employeedata.js'
// import TojoyTitleContent from '@/components/common/initiate-team/title-content'
const defaultAvatar = require('@/assets/images/profile.png')
export default {
  name: 'personalCenter-infoleftbar',
  components: {
    // TojoyTitleContent
  },
  data() {
    return {
      defaultAvatar,
      topoptiontext: '我的资料',
      wedata: [
        {
          val: '我的资料',
          routername: 'personalCenter-material',
          class: 'iconwodeziliao'
        },
        {
          val: '我的组织',
          routername: 'personalCenter-organization',
          class: 'iconwodezuzhi'
        },
        {
          val: '关于我们',
          routername: 'personalCenter-aboutus',
          class: 'iconguanyuwomen'
        },
        {
          val: '隐私协议',
          routername: 'personalCenter-agreement',
          class: 'iconxieyiguanli'
        }
      ],
      attention: [
        {
          imgurl: require('../../assets/images/myconcerned.png'),
          explanation: '',
          routername: 'personalCenter-attentionme',
          val: '关注我的'
        },
        {
          imgurl: require('../../assets/images/followme.png'),
          explanation: '',
          routername: 'personalCenter-myattention',
          val: '我关注的'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      orgChain: state =>
        state.userInfo.jobList && state.userInfo.jobList.length > 0
          ? state.userInfo.jobList[0].orgChain
          : '',
      jobName: state =>
        state.userInfo.jobList && state.userInfo.jobList.length > 0
          ? state.userInfo.jobList[0].jobName
          : '',
      medalGrade: state => state.userInfo.medalGrade,
      perfectNum: state => state.userInfo.perfectNum,
      avatarName: state => (state.userInfo.userName ? state.userInfo.userName.slice(-2) : ''),
      countData: state => state.personcenter.countData
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        let currentRoute = this.wedata.find(w => w.routername === this.$route.name)
        if (!currentRoute) {
          const topnav = [
            {
              val: '个人中心',
              routername: 'personalCenter-material'
            },
            {
              val: '意见反馈',
              routername: 'personalCenter-opinion'
            },
            {
              routername: 'personalCenter-attentionme',
              val: '关注我的'
            },
            {
              routername: 'personalCenter-myattention',
              val: '我关注的'
            }
          ]
          currentRoute = topnav.find(t => t.routername === this.$route.name)
        }
        this.topoptiontext = currentRoute?.val
      }
    },
    countData: {
      deep: true,
      handler(newData, oldval) {
        this.attention[0].explanation =
          this.countData?.attentionMy == 0
            ? '暂时未有人关注'
            : '共' + this.countData?.attentionMy + '人'
        this.attention[1].explanation =
          this.countData?.myAttention == 0
            ? '暂时未有关注人'
            : '共' + this.countData?.myAttention + '人'
      }
    }
  },
  created() {
    this.loadCountData()
  },
  methods: {
    ...mapActions('personcenter', ['loadCountData']),
    // count() {
    //   count().then(res => {
    //     if (res && res.code === '000000') {
    //       this.attention[0].explanation = res.data.attentionMy == 0 ? '暂时未有人关注' : '共' + res.data.attentionMy + '人'
    //       this.attention[1].explanation = res.data.myAttention == 0 ? '暂时未有关注人' : '共' + res.data.myAttention + '人'
    //     }
    //   })
    // },
    format(percentage) {
      // return percentage === 100 ? '满' : `${percentage}%`;
      return '已完善' + `${this.perfectNum}` + '%'
    },
    routerPush(obj) {
      this.topoptiontext = obj.val
      this.$router.push({ name: obj.routername })
    }
  }
}
</script>
<style lang="scss" scoped>
.infocontent {
  display: flex;
  .leftHeaderBar {
    width: 381px;
    height: 100%;
    box-shadow: 1px 0px 0px 0px #eeeeee;
    overflow: auto;
    position: relative;
    z-index: 1;
    background: #fff;
    .iconjiantou {
      font-size: 12px;
      color: #cccccc;
    }
    header {
      display: flex;
      align-items: center;
      flex-flow: column;
      padding: 0 15px;
      .imgBg {
        width: 75px;
        height: 75px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin-top: 30px;
        .avatar {
          display: inline-block;
          border-radius: 50%;
          width: 67px;
          height: 67px;
          margin: 4px;
        }
        .avatardefault {
          background: $blue;
          line-height: 32px;
          color: #fff;
          font-size: 22px;
          line-height: 67px;
          text-align: center;
        }
      }
      .name {
        font-size: 22px;
        color: #333333;
        margin-top: 13px;
      }
      .position {
        font-size: 12px;
        color: #999999;
        margin-top: 12px;
      }
      .department {
        font-size: 12px;
        color: #999999;
        line-height: 16px;
        margin-top: 8px;
      }
      .progress {
        width: 60%;
        height: 62px;
        margin-top: 16px;
        /deep/.el-progress {
          .el-progress-bar {
            padding-right: 68px !important;
            margin-right: -85px !important;
          }
          .el-progress__text {
            font-size: 12px !important;
            color: #999999;
            flex-shrink: 0;
          }
        }
        .el-progress--line {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .attention {
      .attention_option {
        margin-left: 7%;
        width: 86%;
        height: 80px;
        border-radius: 5px 5px 0 0;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        cursor: pointer;
        .attention_banner {
          width: calc(100% - 92.1px);
          display: flex;
          flex-flow: column;
          justify-content: center;
          p:nth-child(1) {
            font-size: 16px;
            color: #333333;
          }
          p:nth-child(2) {
            font-size: 10px;
            color: #999999;
          }
        }
        img {
          width: 24.1px;
          height: 23px;
          display: inline-block;
          margin: 28px 8px 29px 21px;
        }
        .rightoption {
          width: 10px;
          height: 18px;
          color: #cccccc;
        }
        &:hover {
          background: $hover-block;
        }
      }
      .activeattention_option {
        background: $hover-block;
      }
      .attention_option:not(:first-child) {
        border-top: none;
        border-radius: 0 0 5px 5px;
      }
    }
    .wedata {
      margin-top: 20px;
      margin-left: 20px;
      .wedataList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #eeeeee;
        cursor: pointer;
        padding: 0 20px 0 10px;
        &:hover {
          background: $hover-block;
        }
        div {
          display: flex;
          align-items: center;
          .iconfont {
            font-size: 16px;
            line-height: 16px;
            margin-right: 15px;
            color: #666;
          }
          p {
            font-size: 16px;
            line-height: 16px;
            color: #333333;
          }
        }
      }
      .activeLeftbar {
        background: $hover-block;
      }
    }
  }
  .conterHeaderbar {
    height: 100%;
    width: 80%;
    .feedBack {
      height: 50px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      padding-left: 30px;
    }
    .topoption {
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      color: #333333;
      padding-left: 30px;
    }
    .contentRouterview {
      background: #f5f6f7;
      width: 100%;
      height: calc(100% - 50px);
      overflow: auto;
    }
    .notcontentRouterview {
      background: #f5f6f7;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
