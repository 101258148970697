<script lang="jsx">
import TojoyBreadcrumb from '@/components/common/breadcrumb'
import InfoLeftBar from './infoleftbar'

export default {
  name: 'personalCenter-index-view',
  components: {
    TojoyBreadcrumb,InfoLeftBar
  },
  data() {
    return {
      breadcrumbList: [
        {
          title: '首页',
          route: 'Home'
        },
        {
          title: '个人中心',
          route: 'infoleftbar-material'
        }
      ]
    }
  },
  mounted() {
  },
  render(h) {
    return (
      <div class="infocontent">
        <tojoy-breadcrumb ref="tojoyBreadcrumb" breadcrumb-data={this.breadcrumbList}/>
        <info-left-bar class="leftBar"/>
      </div>
    )
  },
}
</script>

<style lang="scss">
  .infocontent{
    height: calc(100% - 47px);
    .leftBar{
      background: #ffffff;
      // width: 20%;
      height: 100%;
    }
  }
</style>
